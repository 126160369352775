.root {
  text-align: center;
  cursor: pointer;
  user-select: none;
  transform: translate(0, -50%);
  opacity: .75;
}

.root > span {
  transform: translate(0, 50%);
  position: relative;
  background-color: white;
  display: inline-block;
  padding: 0 10px;
  font-size: small;
  font-weight: inherit;
  z-index: 1;
}

.line {
  transform: translate(0, -50%);
  position: relative;
  height: 1px;
  background: #484848;
}
