@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

#background-video {
    position: absolute;
    overflow: hidden;
    z-index: -1;
    width: 100%;
    height: 100%;
}

#background-video > video {
    height: auto;
    width: auto;
    min-width: 100%;
    min-height: 100%;
}

#header > a {
    display: table;
    margin: auto;
}

#body {
    height: 100%;
    color: white;
    font-size: 3em;
    font-family: Roboto;
    display: flex;
    flex-flow: column;
    margin-top: 50px;
}

#filled-uncontrolled {
    background: #E0E0E0;
}

#form {
    display: table;
    margin: auto;
}

#form > button {
    height: 55px;
    margin-left: 5px;
    margin-top: 16px;
}

// disable little number thingy on input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield; /* Firefox */
}

#logo {
    max-width: 200px;
}

/* Mobile */
@media screen and (max-width: 600px) {
    #logo {
        max-width: 115px;
    }

    #form > button {
        max-width: 300px;
    }

    #body {
        margin-top: 0px;
    }

    #form div {
        max-width: 90px;
    }
}

