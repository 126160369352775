@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.googleMap_map__3ZGpb {
  height: 500px;
  width: 80%;
  margin: 0 auto 0 auto;
  position: relative; }

.googleMap_overlay__2qc7F {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; }

.googleMap_spinner__33rCh {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content; }

.googleMap_filter__2Yasl {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  color: white;
  opacity: .3; }

.googleMap_marker__3Cgfc {
  position: absolute;
  top: -35px;
  left: -12px;
  border-radius: 50%;
  border: 8px solid;
  background-color: white;
  width: 8px;
  height: 8px;
  cursor: pointer; }

.googleMap_marker__3Cgfc::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  bottom: -30px;
  left: -6px;
  border: 10px solid transparent;
  border-top: 17px solid; }

.googleMap_me__8iqE0 {
  width: 10px;
  height: 10px;
  background: #5285ec;
  border: 3px solid white;
  border-radius: 50px;
  box-shadow: 0px 0px 5px 6px #5285ec36; }

@media screen and (max-width: 600px) {
  .googleMap_map__3ZGpb {
    height: 400px;
    width: 80%;
    margin: auto;
    position: relative; } }

.showMore_root__1rlvL {
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  opacity: .75; }

.showMore_root__1rlvL > span {
  -webkit-transform: translate(0, 50%);
          transform: translate(0, 50%);
  position: relative;
  background-color: white;
  display: inline-block;
  padding: 0 10px;
  font-size: small;
  font-weight: inherit;
  z-index: 1; }

.showMore_line__3jFiR {
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  position: relative;
  height: 1px;
  background: #484848; }

.businessPopout_root__VOnjq {
  position: absolute;
  z-index: 2;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
  left: 50%;
  top: 50%;
  padding-bottom: 40px;
  pointer-events: none; }

.businessPopout_root__VOnjq > div {
  background: white;
  border-radius: 10px 10px 10px 10px;
  padding: 10px;
  box-shadow: 0px 0px 15px 0px #000000ad;
  pointer-events: all; }

.businessPopout_root__VOnjq > div > div, a {
  margin-bottom: 2px;
  font-size: medium;
  font-weight: normal;
  color: #484848; }

.businessPopout_root__VOnjq > div > :first-child {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: large; }

.spotted_btn__OBU39 {
  position: absolute !important;
  z-index: 2;
  -webkit-transform: translate(-50%, 10px);
          transform: translate(-50%, 10px);
  left: 50%;
  top: 50%; }

.spotted_modal__IpqMe {
  position: relative;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  outline: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

#background-video {
  position: absolute;
  overflow: hidden;
  z-index: -1;
  width: 100%;
  height: 100%; }

#background-video > video {
  height: auto;
  width: auto;
  min-width: 100%;
  min-height: 100%; }

#header > a {
  display: table;
  margin: auto; }

#body {
  height: 100%;
  color: white;
  font-size: 3em;
  font-family: Roboto;
  display: flex;
  flex-flow: column;
  margin-top: 50px; }

#filled-uncontrolled {
  background: #E0E0E0; }

#form {
  display: table;
  margin: auto; }

#form > button {
  height: 55px;
  margin-left: 5px;
  margin-top: 16px; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */ }

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */ }

#logo {
  max-width: 200px; }

/* Mobile */
@media screen and (max-width: 600px) {
  #logo {
    max-width: 115px; }
  #form > button {
    max-width: 300px; }
  #body {
    margin-top: 0px; }
  #form div {
    max-width: 90px; } }

