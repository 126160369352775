.map {
  height: 500px;
  width: 80%;
  margin: 0 auto 0 auto;
  position: relative;
}

.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.spinner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: min-content;
}

.filter {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  color: white;
  opacity: .3;
}

.marker {
  position: absolute;
  top: -35px;
  left: -12px;
  border-radius: 50%;
  border: 8px solid;
  background-color: white;
  width: 8px;
  height: 8px;
  cursor: pointer;
}

.marker::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  bottom: -30px;
  left: -6px;
  border: 10px solid transparent;
  border-top: 17px solid;
}

.me {
  width: 10px;
  height: 10px;
  background: #5285ec;
  border: 3px solid white;
  border-radius: 50px;
  box-shadow: 0px 0px 5px 6px #5285ec36;
}

@media screen and (max-width: 600px) {
  .map {
    height: 400px;
    width: 80%;
    margin: auto;
    position: relative;
  }

}
