.btn {
  position: absolute !important;
  z-index: 2;
  transform: translate(-50%, 10px);
  left: 50%;
  top: 50%;
}

.modal {
  position: relative;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  outline: none;
  width: fit-content;
}
