.root {
  position: absolute;
  z-index: 2;
  transform: translate(-50%, -100%);
  left: 50%;
  top: 50%;
  padding-bottom: 40px;
  pointer-events: none;
}

.root > div {
  background: white;
  border-radius: 10px 10px 10px 10px;
  padding: 10px;
  box-shadow: 0px 0px 15px 0px #000000ad;
  pointer-events: all;
}

.root > div > div, a {
  margin-bottom: 2px;
  font-size: medium;
  font-weight: normal;
  color: #484848;
}

.root > div > :first-child {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: large;
}
